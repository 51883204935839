@import '../../../../index.css';

.retry {
    align-items: center;
    display: flex;
    width: 80%;
    font-family: 'Helvetica Neue';
    font-weight: 600;
    background-color: black;
    height: 60px;
    text-decoration: none;
    border-radius: 10px;
    color: white;
    flex-direction: row;
    justify-content: center;
    font-size: 18px;
    border: none;
    margin-top: 20px;

    &:active {
        background-color: rgb(135, 135, 135);
    }
}