@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");


@font-face {
  font-family: "jost";
  src: local("MyFont"), url(assets/fonts/one.ttf);
}
@font-face {
  font-family: "jost-bold";
  src: local("MyFont"), url(assets/fonts/two.ttf);
}
@font-face {
  font-family: "interstate-regular";
  src: local("MyFont"), url(assets/fonts/Interstate-Regular-Font.ttf)
}
@font-face {
  font-family: "interstate-bold";
  src: local("MyFont"), url(assets/fonts/Interstate-Bold.otf)
}
.but {
  color: #162138;
}

* {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

.App {
  text-align: center;
}

body {
  overflow: hidden;
}
.video-react-has-started .video-react-control-bar {
  display: none !important;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.1s, opacity 0.1s;
}

.fadeOut{
  opacity:0;
  width:0;
  height:0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

}
.div_align {
  display: "flex";
  margin: "auto";
  text-align: center;
}
.align {
  border: 0px pink solid;
}
.coucou {
  text-align: center;
  justify-content: center;
  align-items: center;
}
.caroussel {
  overflow: hidden
}
.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}
.caroussel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.indicators {
  display: flex;
  justify-content: center;
}
.indicators > button {
  margin: 5px;
}
.indicators > button.active {
  background-color: green;
  color: #fff
}
.video-react-has-started .video-react-control-bar {
  display: none !important;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.1s, opacity 0.1s;
}
